<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="brand"
        type="text"
        name="Marca"
        hint="Ricerca per marca"
        :cols="3"
        dense
      />
      <FormItem
        v-model="model"
        type="text"
        name="Modello"
        hint="Ricerca per modello"
        :cols="3"
        dense
      />
      <FormItem
        v-model="type"
        type="text"
        name="Tipo"
        hint="Ricerca per tipo di prodotto"
        :cols="3"
        dense
      />
      <FormItem
        v-model="part_number"
        type="text"
        name="Seriale"
        hint="Ricerca per seriale"
        :cols="3"
        dense
      />
    </v-row>
    <v-row dense>
      <FormItem
        v-model="external_id"
        type="text"
        name="ID Esterno"
        hint="Ricerca per ID esterno"
        :cols="3"
        dense
      />
      <FormItem
        v-model="active"
        type="select"
        name="Stato"
        hint="Ricerca per stato"
        :values="[
          { value: 'true', text: 'Attivo' },
          { value: 'false', text: 'Inattivo' },
        ]"
        :cols="3"
        dense
      />
      <FormItem
        v-model="variant"
        type="text"
        name="Variante"
        hint="Ricerca per Variante"
        :cols="3"
        dense
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'

import { createHelpers } from 'vuex-map-fields'
import { mapState, mapMutations } from 'vuex'

const { mapFields } = createHelpers({
  getterType: 'products/getFiltersFields',
  mutationType: 'products/SET_FILTER_FIELDS',
})

export default {
  name: 'ProductsSectionSearch',
  components: {
    FormItem,
    BaseSectionSearch,
  },
  computed: {
    ...mapFields([
      'brand',
      'model',
      'type',
      'part_number',
      'external_id',
      'active',
      'variant',
    ]),
    ...mapState('products', ['filters']),
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    ...mapMutations('products', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
