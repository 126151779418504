<template>
  <v-container fluid>
    <ProductsSectionSearch @search="() => $refs.table.refresh()" />

    <BaseTable
      ref="table"
      title="Elenco Prodotti"
      module="products"
      :headers="headers"
      :actions="actions"
    >
      <template v-slot:actions>
        <v-btn
          v-if="canUser('products', 'create')"
          color="green"
          dark
          tile
          @click="openModal"
        >
          Aggiungi Prodotto
        </v-btn>
      </template>
    </BaseTable>

    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title>
          {{ editMode ? 'Dettagli' : 'Inserisci' }} Prodotto
        </v-card-title>
        <v-card-text>
          <ProductsForm
            :title="null"
            :edit="editMode"
            @submitted="onSubmitted"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import ProductsSectionSearch from '@components/products/ProductsSectionSearch.vue'
import ProductsForm from '@components/products/ProductsForm.vue'
import { mapActions, mapMutations } from 'vuex'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'ProductsSearch',
  page: {
    title: 'Prodotti',
  },
  components: { ProductsSectionSearch, ProductsForm, BaseTable },
  data() {
    return {
      dialog: false,
      editMode: false,
      headers: [
        {
          text: 'Tipo',
          value: 'type',
        },
        {
          text: 'Marca',
          value: 'brand',
        },
        {
          text: 'Modello',
          value: 'model',
        },
        {
          text: 'Variante',
          value: 'variant',
        },
        {
          text: 'Seriale',
          value: 'part_number',
        },
        {
          text: 'Id esterno',
          value: 'external_id',
        },
        {
          text: 'Attivo',
          value: 'active',
        },
      ],
      actions: [
        // {
        //   label: 'Scheda Prodotto',
        //   icon: 'mdi-magnify',
        //   color: 'primary',
        //   to: (item) => ({ name: 'products_detail', params: { id: item.id } }),
        //   onItemCondition: () => this.canUser('products', 'read'),
        // },
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () => this.canUser('products', 'update'),
          handler: this.openModal,
        },
        {
          label: 'Elimina',
          icon: 'mdi-delete',
          color: 'red',
          onItemCondition: () => this.canUser('products', 'delete'),
          handler: this.remove,
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('products', ['removeItem', 'update']),
    ...mapMutations('products', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    async remove(item) {
      const res = await this.$dialog.confirm({
        text:
          "Proseguire con l'eliminazione del products ?\n l'azione non è reversibile!",
        title: 'Attenzione',
      })

      if (!res) return

      await this.removeItem(item.id)
      await this.$refs.table.refresh()
    },

    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      this.editMode = !!startingPoint.id
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
